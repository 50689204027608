<template>
  <v-snackbar
    v-model="show"
    class="mt-4"
    color="neutral"
    rounded="xl"
    :timeout="timeout"
    light
    top
    app
  >
    <div class="d-flex align-center">
      <v-icon :color="type" left> {{ icons[type] }} </v-icon>

      <div class="text-subtitle-2">{{ message }}</div>

      <v-spacer />

      <v-btn icon small @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      icons: {
        success: "mdi-check-circle",
        error: "mdi-close-box",
        warning: "mdi-alert-rhombus",
        info: "mdi-information",
      },
      message: "",
      type: "",
      timeout: 4000,
    };
  },

  methods: {
    handleAlert(data) {
      this.message = data.message;
      this.type = data.type;
      this.timeout = data.fixed ? -1 : data.timeout || 4000;
      this.show = true;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
