import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: [], // persistent variable on state
});

export default new Vuex.Store({
  state: {},

  getters: {
    apiURL: () => process.env.VUE_APP_API_URL,
  },

  mutations: {},

  actions: {},

  plugins: [vuexLocal.plugin],

  modules: {},
});
