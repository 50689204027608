<template>
  <v-app id="app">
    <v-main class="background">
      <router-view />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import AlertBar from "./components/alertBar/AlertBar.vue";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
  },

  methods: {
    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
