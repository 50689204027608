import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:username?",
    name: "Home",
    component: Home,
    children: [
      {
        path: "",
        name: "Store",
        component: () => import("../views/home/children/store/Store.vue"),
      },
      {
        path: ":id",
        name: "Checkout",
        component: () => import("../views/home/children/checkout/Checkout.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
