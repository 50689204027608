<template>
  <div class="d-flex align-center justify-center">
    <v-progress-circular indeterminate size="48" color="surface" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
