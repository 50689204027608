<template>
  <v-container
    :class="$vuetify.breakpoint.mdAndUp || 'pa-0'"
    :fluid="$vuetify.breakpoint.smAndDown"
    fill-height
  >
    <v-row class="fill-height" justify="center" no-gutters>
      <v-col cols="12" md="6" lg="5" xl="4">
        <v-card color="neutral" height="100%">
          <div v-if="loading" class="d-flex fill-height justify-center">
            <loader />
          </div>

          <div v-else-if="Object.keys(store).length">
            <router-view
              :owner="owner"
              :store="store"
              :products="products"
              :socialMedia="socialMedia"
            />

            <div
              class="d-flex flex-column justify-center align-center pt-12 pb-6 footer"
            >
              <section class="text-center">
                <div class="text-body-2 mb-2">Crie sua conta ALIO</div>

                <div class="d-flex align-center mb-8">
                  <v-card
                    class="rounded-lg"
                    color="foreground px-2 py-2 mr-2"
                    flat
                  >
                    <input
                      class="d-flex align-center text-body-2 mr-2"
                      :value="`client.alio.bio/auth/sign-up`"
                      :style="`color: ${store.color}`"
                      readonly
                      @focus="copyInvite()"
                    />
                  </v-card>

                  <v-btn
                    class="text-none rounded-lg"
                    outlined
                    small
                    icon
                    @click="copyInvite()"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </section>

              <v-img :src="require('@/assets/logo.svg')" max-width="6rem" />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="pa-6 rounded-lg">
        <div class="d-flex justify-center mb-8">
          <v-icon size="128px"> mdi-alert-circle-outline </v-icon>
        </div>

        <div class="text-center secondary--text text-h6 mb-4">
          Ops! Parece que algo deu errado.
        </div>

        <div class="text-center text1--text mb-4">
          Não encontramos a loja que você procura, verifique se o endereço está
          correto!
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { request } from "@/services";

export default {
  name: "Home",

  data() {
    return {
      loading: true,
      dialog: false,
      owner: {},
      store: {},
      products: [],
      socialMedia: [],
    };
  },

  beforeMount() {
    if (!this.$route.params.username)
      location.href = "https://homepage.alio.bio";
    else this.getStore();
  },

  methods: {
    async getStore() {
      try {
        const payload = {
          method: "getLoja",
          username: this.$route.params.username,
        };

        const { data } = await request(payload);

        this.store = {
          id: data.loja.minhaLoja.id,
          color: data.loja.minhaLoja.corLoja.toUpperCase(),
          name: data.loja.minhaLoja.nomeNaLoja,
          published: data.loja.minhaLoja.published,
          themeID: data.loja.minhaLoja.temaID,
        };

        this.socialMedia = data.loja.redesSocias.map((el) => ({
          id: el.redeID,
          image: el.imgRede,
          link: el.linkRede,
          name: el.nomeRede,
          username: el.userRedeSocial,
        }));

        this.products = data.loja.produtos.map((el) => ({
          id: el.id,
          createdAt: el.dataCriacao,
          productImage: el.imgProduto,
          linkID: el.linkID,
          title: el.titulo,
          caption: el.subtitulo,
          btnTitle: el.tituloBotao,
          grade: el.nota,
          type: el.tipo,
          productType: el.produtoTipo,
          published: el.published === "1",
          valid: el.valid === "1",
          visible: el.visivel === "1",
          highlight: el.tremer === "1",
          checkout: {
            id: el.checkout.id,
            image: el.checkout.checkoutImage,
            title: el.checkout.titulo,
            description: el.checkout.descricao,
            price: el.checkout.preco,
            promotionalPrice: el.checkout.precoPromocional,
            enablePromotionalPrice: el.checkout.ativaPrecoPromocional === "1",
            quantity: el.checkout.limiteQnt,
            linkID: el.checkout.linkID,
            fields:
              el.checkout.campos !== "{}"
                ? JSON.parse(el.checkout.campos).map((field) => ({
                    label: field.nomeField,
                    field: field.nomeField.toLowerCase().replace(/ /g, "-"),
                    type: field.tipoField,
                    required: field.obrigatorio === "1",
                  }))
                : [],
          },
          ...(el.redirectLink && { redirectLink: el.redirectLink }),
          expanded: false,
        }));

        this.owner = {
          biography: data.userdata.biografia,
          name: data.userdata.nome,
          image: data.userdata.userImage,
          username: data.userdata.username,
          activeIndication: data.userdata.ativaInvite === "1",
          maluFlag: data.userdata.alunoMalu === "1",
          carolFlag: data.userdata.alunaCarol === "1",
        };

        console.log(this.owner);
      } catch (err) {
        this.dialog = true;
      } finally {
        this.loading = false;
      }
    },

    copyInvite() {
      const url = "https://client.alio.bio/auth/sign-up/";
      navigator.clipboard.writeText(url);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
